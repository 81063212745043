<template>
  <div class="table-box">
         <el-row class="info1">
      <el-table :data="w7UserRespList" style="width: 100%">
        <el-table-column
          v-for="item in columns1"
          :key="item.key"
          :prop="item.key"
          :label="item.label"
        >
        <template slot-scope="scope">
          <div class="row-img" v-if="item.key=='facePicUrl'">
              <img :src="scope.row[item.key]" class="table-row-img" />
            </div>
            <span v-else>{{scope.row[item.key]}}</span>
        </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
       columns1: [
        { label: "用户", key: "realName", width: 80 },
        { label: "注册时间", key: "registerTime" },
        { label: "人脸识别开锁次数", key: "unlockTimes" },
        { label: "最近一次使用人脸开锁时间", key: "lastUnlockTIme" },
        { label: "人脸照片", key: "facePicUrl" }
      ],
      w7UserRespList: [],
    };
  },
  created() {
    console.log("individualLandlord created!!");
    
  },
  activated() {
    console.log("individualLandlord activated!!");
    this.getOrdeData(this.$route.params.id);
  },
  mounted(){
    
  },
  methods: {
    getOrdeData(roomId) {
      let dto = {
        roomId: roomId
      };
      this.get("/device-service/w7/detail/"+roomId,).then(res => {
        console.log(res);
        this.w7UserRespList = res.w7UserRespList;
        this.apartmentName=res.apartmentName;
        this.mac=res.mac;
        this.firmwareVersion=res.firmwareVersion;
        this.lockItemList=res.lockItemList;
        this.buildingNo=res.buildingNo;
        this.roomNo=res.roomNo;
      });
    }
  }
};
</script>
<style lang="scss">
.table-box{
  height: 620px;
}
.info1 {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    .txt {
      margin-right: 20px;
    }
  }
  .table-row-img{
    width: 75px;
  }
</style>