<template>
  <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
        opt: {
        search: [
          {
            key: "date",
            label: "起止日期",
            timeType: "yyyy-MM-dd HH:mm:ss",
            format: "yyyy-MM-dd",
            type: "daterange"
          },
          {
            key: "unlockMethod",
            label: "开锁方式",
            type: "select",
            opt: {
              list: [
                {
                  label: "一键开锁",
                  value: "1"
                },
                {
                  label: "机械钥匙开锁",
                  value: "2"
                },
                {
                  label: "人脸识别开锁",
                  value: "3"
                },
                 {
                  label: "蓝牙开锁",
                  value: "4"
                },
                 {
                  label: "远程开锁",
                  value: "5"
                },
                 {
                  label: "特殊开门事件",
                  value: "11"
                },
              ]
            }
          }
        ],
        columns: [
          { label: "序号", key: "sequenceNumber", width: 80 },
          { label: "开锁方式", key: "unlockMethodStr" },
          { label: "开锁用户", key: "unlockUserName" },
          { label: "门锁电量", key: "batteryPower" },
          { label: "开锁时间", key: "unlockDate" },
          {
            label: "开锁结果",
            key: "unlockStateStr"
          },
          { 
            label: "人脸照片", 
            type:"img",
            key: "facePicUrl",
            on: row => {
              _this.$alert(`<img src="${row.facePicUrl}">`, "查看人脸照片", {
                dangerouslyUseHTMLString: true,
                callback: action => {}
              });
            }
             },
        ]
      },
      roomList: [],
    };
  },
  created() {
    console.log("doorOpeningLog created!!");
    
  },
  activated() {
    console.log("doorOpeningLog activated!!");
    // this.$refs.appList.onRefresh();
  },
  mounted(){
    console.log('mounted')
     
  },
  methods: {
    onGet(opt) {
      console.log(opt.searchForm);
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        gatewayId: this.$route.params.id,
        beginTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
        endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
        unlockMethod: opt.searchForm["unlockMethod"]
          ? opt.searchForm["unlockMethod"]
          : null
      };
      this.post("/device-service/w7/pageQuery/UnlockRecord", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.sequenceNumber=i+1;
          item.unlockMethodStr = [
            "一键开锁",
            "机械钥匙开锁",
            "人脸识别开锁",
            "蓝牙开锁",
            "远程开锁",
          ][item.unlockMethod - 1];
          if(item.unlockMethod==11){
            item.unlockMethodStr='特殊开门事件'
          }
          item.unlockStateStr = ["等待开锁", "开锁成功","开锁失败"][item.unlockState];
          item.date = this.format(item.createdDate);
          item.batteryPower = item.batteryPower + "%";
          item.unLockUserStr = item.unLockUser ? item.unLockUser : "--";
          item.unLockUserPhoneStr = item.unLockUserPhone
            ? item.unLockUserPhone
            : "--";
          if (item.unlockState == "2") {
            item.bk = 2;
          } else {
            item.bk = 0;
          }
        }
        opt.cb(res.data);
      });
    },
  }
};
</script>
<style lang="scss">
.table-box{
  height: 620px;
}
</style>