<template>
  <div class="lock-log">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <el-row class="info">
      <el-col :span="24">
        <!-- <span class="txt">门锁信息</span> -->
        <div>
        <div class="txt">门锁房间: {{apartmentName}}{{buildingNo}}栋{{roomNo}}房</div>
        <div class="txt">门禁MAC: {{mac}}</div>
        <div class="txt">
          <div v-for="(item,index) in lockItemList" :key="index">
        <div class="txt2">关联门锁型号: {{lockTypeArr[item.lockType]}}</div>
        <div class="txt3">门锁MAC: {{item.lockMac}}</div>
          </div>
        </div>
        <div class="txt">设备端软件版本: {{firmwareVersion}}</div>
        </div>
        
      </el-col>
    </el-row>
    <ul class="tab-ul">
      <li :class="{'ative':currentTab=='doorOpeningLog'}" @click="toggleTab('doorOpeningLog')">开门日志</li>
      <li :class="{'ative':currentTab=='userInformation'}" @click="toggleTab('userInformation')">用户信息</li>
    </ul>
    <keep-alive>
    <doorOpeningLog :is="currentTab"></doorOpeningLog>
    </keep-alive>
  </div>
</template>
<script>
import doorOpeningLog from './doorOpeningLog';
  import userInformation from './userInformation';
export default {
   components: { 
      doorOpeningLog,
      userInformation,
    },
  data() {
    let _this = this;
    return {
      title: "分离式门禁详情",
      currentTab: 'doorOpeningLog',
      row: {},
      id: "",
      columns1: [
        { label: "用户", key: "realName", width: 80 },
        { label: "注册时间", key: "registerTime" },
        { label: "人脸识别开锁次数", key: "unlockTimes" },
        { label: "最近一次使用人脸开锁时间", key: "lastUnlockTIme" },
        { label: "人脸照片", key: "facePicUrl" }
      ],
      w7UserRespList: [],
      apartmentName:'',
      mac:'',
      firmwareVersion:'',
      lockItemList:'',
      buildingNo:'',
      roomNo:'',
    };
  },
  activated() {
    console.log("lock-log activated!!");
    this.currentTab='doorOpeningLog';
    this.getOrdeData(this.$route.params.id);
  },
  methods: {
    toggleTab: function (tab) {
        this.currentTab = tab; // tab 为当前触发标签页的组件名
      },
    getOrdeData(roomId) {
      let dto = {
        roomId: roomId
      };
      this.get("/device-service/w7/detail/"+roomId,).then(res => {
        console.log(res);
        this.apartmentName=res.apartmentName;
        this.mac=res.mac;
        this.firmwareVersion=res.firmwareVersion;
        this.lockItemList=res.lockItemList;
        this.buildingNo=res.buildingNo;
        this.roomNo=res.roomNo;
      });
    }
  }
};
</script>
<style lang="scss">
.lock-log {
  .danger {
    color: #f56c6c;
  }
  .titleSpans {
    padding-bottom: 20px;
  }
  .titleSpan {
    color: #606266;
  }
  .info1 {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    .txt {
      margin-right: 20px;
    }
  }
  .info {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    background-color: #e4e7ed;

    .txt {
      vertical-align: top;
      display: inline-block;
      margin-right: 20px;
    }
    .txt2 {
      display: inline-block;
      width: 165px;
    }
    .txt3 {
      display: inline-block;
    }
  }
  .table-row-img{
    width: 75px;
  }
  // tab样式
  .tab-ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    height: 42px;
    box-sizing: border-box;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 2px solid #e4e7ed;

    li {
      cursor: pointer;
      padding-left: 18px;
      padding-right: 18px;
      height: 42px;
      line-height: 42px;
      float: left;
      text-align: center;
      margin-right: 13px;
      font-size: 15px;
      background-color: #ffffff;
      color: #303133;
      box-sizing: border-box;
      border-bottom: 2px solid #e4e7ed;

      &.ative {
        // color: #ffffff;
        // background: #5e74f1;
        color: #30BAC1;
        border-bottom: 2px solid #30BAC1;
      }
    }

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }
   .landlord-manage-box .el-tab-pane{
  height: 650px;
  }
}
</style>
<style lang="scss" scope>
.el-message-box {
 min-width: 200px!important;
}
</style>
